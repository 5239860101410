import React from 'react'
import { CookiesProvider } from 'react-cookie'

import dispatchConversionsAPI from './utils/dispatchConversionsAPI'

export function onRouteUpdate({ location }) {
  const event_source_url = `${location.protocol}//${location.host}${location.pathname}`

  dispatchConversionsAPI('ViewContent', {
    event_source_url,
  })
}

export function wrapRootElement({ element }) { return (
  <CookiesProvider>{element}</CookiesProvider>
)}
