import axios from './axios';
import { getUserAgent } from 'universal-user-agent';
import { address } from 'ip';

export default (event_name, data = {}) => {
  axios.post('https://graph.facebook.com/v12.0/2751081048274633/events', {
    data: [
      {
        event_name,
        event_time: Math.floor(new Date() / 1000),
        action_source: 'website',
        ...data,
        user_data: {
          client_ip_address: address(),
          client_user_agent: getUserAgent(),
          ...data.user_data || {},
        }
      }
    ],
    test_event_code: 'TEST54150',
}, {
    headers: {
      Authorization: 'Bearer EAAClo520XjoBAFAva6fLBETPyAkLgE3FdWz4DI3DXOZB9bea1ZAlWHbCjbNKwt6Rfvldysrcybc0DQRZA3ERjfFqCi7eno7Qas1kQHD3ZCDMUxLGI4s8ZBRDFPAbkMrbcfbRraWdFLH7f73Uu9lKvticZCWWIfV0AScB7psvvPQZAOE33kZBpqCBcgBZCXfqK7KsZD'
    }
  })
}
