import axios, { interceptors } from 'axios'

interceptors.response.use(undefined, (error) => {
  const Sentry = __SENTRY__
  Sentry && Sentry.hub.captureException(error)

  return Promise.reject(error)
})

export default axios
