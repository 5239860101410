// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-boleto-100-tsx": () => import("./../../../src/pages/boleto-100.tsx" /* webpackChunkName: "component---src-pages-boleto-100-tsx" */),
  "component---src-pages-boleto-50-tsx": () => import("./../../../src/pages/boleto-50.tsx" /* webpackChunkName: "component---src-pages-boleto-50-tsx" */),
  "component---src-pages-boletos-tsx": () => import("./../../../src/pages/boletos.tsx" /* webpackChunkName: "component---src-pages-boletos-tsx" */),
  "component---src-pages-cartao-empresarial-tsx": () => import("./../../../src/pages/cartao-empresarial.tsx" /* webpackChunkName: "component---src-pages-cartao-empresarial-tsx" */),
  "component---src-pages-conta-pj-digital-tsx": () => import("./../../../src/pages/conta-pj-digital.tsx" /* webpackChunkName: "component---src-pages-conta-pj-digital-tsx" */),
  "component---src-pages-gestao-de-cobranca-tsx": () => import("./../../../src/pages/gestao-de-cobranca.tsx" /* webpackChunkName: "component---src-pages-gestao-de-cobranca-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-informacoes-essenciais-termos-de-uso-tsx": () => import("./../../../src/pages/informacoes-essenciais-termos-de-uso.tsx" /* webpackChunkName: "component---src-pages-informacoes-essenciais-termos-de-uso-tsx" */),
  "component---src-pages-nome-fantasia-mei-tsx": () => import("./../../../src/pages/nome-fantasia-mei.tsx" /* webpackChunkName: "component---src-pages-nome-fantasia-mei-tsx" */),
  "component---src-pages-ouvidoria-tsx": () => import("./../../../src/pages/ouvidoria.tsx" /* webpackChunkName: "component---src-pages-ouvidoria-tsx" */),
  "component---src-pages-pix-gratuito-tsx": () => import("./../../../src/pages/pix-gratuito.tsx" /* webpackChunkName: "component---src-pages-pix-gratuito-tsx" */),
  "component---src-pages-politica-privacidade-tsx": () => import("./../../../src/pages/politica-privacidade.tsx" /* webpackChunkName: "component---src-pages-politica-privacidade-tsx" */),
  "component---src-pages-regulatorio-tsx": () => import("./../../../src/pages/regulatorio.tsx" /* webpackChunkName: "component---src-pages-regulatorio-tsx" */),
  "component---src-pages-termos-autorizacao-consulta-scr-tsx": () => import("./../../../src/pages/termos-autorizacao-consulta-scr.tsx" /* webpackChunkName: "component---src-pages-termos-autorizacao-consulta-scr-tsx" */),
  "component---src-pages-termos-campanha-aumente-seu-limite-tsx": () => import("./../../../src/pages/termos-campanha-aumente-seu-limite.tsx" /* webpackChunkName: "component---src-pages-termos-campanha-aumente-seu-limite-tsx" */),
  "component---src-pages-termos-de-antecipacao-de-recebiveis-de-cartoes-tsx": () => import("./../../../src/pages/termos-de-antecipacao-de-recebiveis-de-cartoes.tsx" /* webpackChunkName: "component---src-pages-termos-de-antecipacao-de-recebiveis-de-cartoes-tsx" */),
  "component---src-pages-termos-de-uso-cartao-de-credito-tsx": () => import("./../../../src/pages/termos-de-uso-cartao-de-credito.tsx" /* webpackChunkName: "component---src-pages-termos-de-uso-cartao-de-credito-tsx" */),
  "component---src-pages-termos-de-uso-do-cartao-de-credito-com-garantia-tsx": () => import("./../../../src/pages/termos-de-uso-do-cartao-de-credito-com-garantia.tsx" /* webpackChunkName: "component---src-pages-termos-de-uso-do-cartao-de-credito-com-garantia-tsx" */),
  "component---src-pages-termos-e-condicoes-de-apis-tsx": () => import("./../../../src/pages/termos-e-condicoes-de-apis.tsx" /* webpackChunkName: "component---src-pages-termos-e-condicoes-de-apis-tsx" */),
  "component---src-pages-termos-e-condicoes-de-coraliados-tsx": () => import("./../../../src/pages/termos-e-condicoes-de-coraliados.tsx" /* webpackChunkName: "component---src-pages-termos-e-condicoes-de-coraliados-tsx" */),
  "component---src-pages-termos-e-condicoes-de-link-de-pagamento-tsx": () => import("./../../../src/pages/termos-e-condicoes-de-link-de-pagamento.tsx" /* webpackChunkName: "component---src-pages-termos-e-condicoes-de-link-de-pagamento-tsx" */),
  "component---src-pages-termos-e-condicoes-do-open-finance-tsx": () => import("./../../../src/pages/termos-e-condicoes-do-open-finance.tsx" /* webpackChunkName: "component---src-pages-termos-e-condicoes-do-open-finance-tsx" */),
  "component---src-pages-termos-indique-e-ganhe-30-tsx": () => import("./../../../src/pages/termos-indique-e-ganhe/30.tsx" /* webpackChunkName: "component---src-pages-termos-indique-e-ganhe-30-tsx" */),
  "component---src-pages-termos-indique-e-ganhe-60-tsx": () => import("./../../../src/pages/termos-indique-e-ganhe/60.tsx" /* webpackChunkName: "component---src-pages-termos-indique-e-ganhe-60-tsx" */),
  "component---src-pages-termos-indique-e-ganhe-90-tsx": () => import("./../../../src/pages/termos-indique-e-ganhe/90.tsx" /* webpackChunkName: "component---src-pages-termos-indique-e-ganhe-90-tsx" */),
  "component---src-pages-termos-planos-pagos-tsx": () => import("./../../../src/pages/termos-planos-pagos.tsx" /* webpackChunkName: "component---src-pages-termos-planos-pagos-tsx" */),
  "component---src-pages-termos-uso-tsx": () => import("./../../../src/pages/termos-uso.tsx" /* webpackChunkName: "component---src-pages-termos-uso-tsx" */)
}

